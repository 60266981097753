/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:066e6578-95b4-49fd-899b-c985b63f6317",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_FNLr7wb6T",
    "aws_user_pools_web_client_id": "5i4trt63urpr4of5a3e53hrpn3",
    "oauth": {},
    "aws_user_files_s3_bucket": "covidxray222240-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://rcjayeuhhfdoza2ydvq7quh6zq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
