
import React, { useState, useEffect } from 'react';
import { Storage, API, Auth, graphqlOperation } from 'aws-amplify';
import { Checkbox, TextField, Button, CircularProgress , Tooltip } from '@material-ui/core';
import { createInferenceHistory } from '../graphql/mutations';
import Alert from '@material-ui/lab/Alert'
import './CovidXRayTest.css';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: "40%",
    },
    customWidth: {
      maxWidth: 100,
    },

  },
}));

function CovidXRayTest(props) {

  const classes = useStyles();
  const [ID, setId] = useState("");
  const [pictureFile, setPictureFile] = useState();
  const [preview, setPreview] = useState();
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [user, setUser] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [newWidth, setWidth] = useState(window.innerWidth);
  const [newHeight, setHeight] = useState(window.innerHeight);
  const [agreeLicense, setAgreeLicense] = useState(false); 
  const [keyForInput, setKeyForInput] = useState(Date.now())
  const [licenseUrl,setLicenseUrl]= useState(process.env.REACT_APP_TEMPLATE_AGREEMENT);
  const uploadTooltip = (
    <>
    {"1. Image format supported : jpg,bmp,png,dcm"}<br/>
    {"2. Min Image size 600x600 "} <br/>
    {"3. Please exclude patient personal information"} <br/>
     {"from X-Ray before process"}
    </>);
  const processTooltip=(
    <>
    {"All images and patient IDs are cleared / deleted "}<br/>
    {"when the user logs off the service or clicks on \"RESET\" "}
    </>
    )
  
  const resetTooltip=(
    <>
    {"Selected image and patient IDs will be cleared."}<br/>
    {"Resets all UI controls/ fields"}
    </>
    
  )
  const getIDValid=()=>{
    return ID.length>0 && !/[^#_a-zA-Z0-9]/.test(ID)
  }
  const isSubMitEnabled = getIDValid() && preview !== undefined && agreeLicense;
  useEffect(() => {
    Auth.currentUserInfo()
    .then(
        res =>{
          const fetchAgreement= async (username)=>{
            try{
              const licenseKey= await Storage.get(username+'/legal_agreement_'+username+'.pdf')
              return licenseKey
            }catch(err){
                return null;
            }
          }

          fetchAgreement(res.username).then(
            response => {
            
              axios.get(response)
              .then(
               fine=>setLicenseUrl(response)
              ).catch(
                err =>console.log(err)
              )

            }
          ).catch(err=>{
            console.log(err)
          })
          
          setUser(res.username)
        }
    )
    
    
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);

  }, [])

  useEffect(() => {
    if (!pictureFile) {
      setPreview(undefined)
      return;
    }
    let objectUrl = null;
    if (pictureFile.name.endsWith(".dcm")) {
      Storage.put(user + '/' + pictureFile.name, pictureFile, {
        contentType: 'image/*'
      }).then(
        res => {
          axios.post(process.env.REACT_APP_API_URL + '/convert', { 'fileToConvert': 'public/' + res.key, 'user': user })
            .then(
              response => {
                let url = response.data
                Storage.get(url.convertedUrl)
                  .then(
                    image => {
                      setPreview(image)
                      return;
                    }
                  )
              }
            ).catch(
              err => {
                alert("Something went wrong")
                handleReset();
              }
            )
        }
      )
    } else {
      objectUrl = URL.createObjectURL(pictureFile)
      setPreview(objectUrl)
    }


    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [pictureFile, user])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setPictureFile(undefined)
      return
    }
    setDiagnosisResult(null)
    setPictureFile(e.target.files[0])
  }

  const handleSave = async () => {
    const result = await Storage.get(diagnosisResult.result_path)
    var link = document.createElement('a');
    link.href = result;
    link.click();
  }

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  



  // const handlePrint = async () => {
  //   const result = await Storage.get(diagnosisResult.result_path)
  //   var printWindow = window.open('', 'PrintWindow', '');
  //   printWindow.document.write('<html><head><title>Print Window</title>');
  //   printWindow.document.write('</head><body ><img src=\'');
  //   printWindow.document.write(result);
  //   printWindow.document.write('\' height="auto" width="100%" /></body></html>');
  //   printWindow.document.close();
  //   printWindow.onload = function () { printWindow.print(); printWindow.close() };
  // }

  const handleReset = () => {
    setDiagnosisResult(null)
    setId("");
    setPictureFile(undefined)
    setPreview(undefined);
    setLoading(false);
    setAgreeLicense(false);
    setKeyForInput(Date.now());
  }

  const handleSubmit = async () => {
    if (pictureFile !== null) {
      setLoading(true);
      const storageResult = await Storage.put(user + '/' + pictureFile.name, pictureFile, {
        contentType: 'image/*',
      })
      let licenseAccepted = "NotAccepted"
      if (agreeLicense) {
        licenseAccepted = "Accepted"
      }
      try {
        const inferenceResult = await axios.get(process.env.REACT_APP_API_URL + "/check?fileToCheck=public/" + storageResult.key + "&user=" + user + "&patient=" + ID + "&license=" + licenseAccepted)
        
        let diagnosisResult = inferenceResult.data
        const inputData = {
          id: storageResult.key + new Date().getTime().toString(), user, patientID: ID, imageID: storageResult.key,
          licenseAcceptance: licenseAccepted,
          covidPositive: diagnosisResult.COVID_POSITIVE,
          timestamp: new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" }) + " IST"
        }
        API.graphql(graphqlOperation(createInferenceHistory, { input: inputData }))
          .then(
            dbupdateRes => console.log("Saved Record Successfully..")
          ).catch(
            err => {
              console.log("Error updating record..")
            }
          )
        if (licenseUrl === process.env.REACT_APP_TEMPLATE_AGREEMENT) {
          let url = await Storage.get(user + '/legal_agreement_' + user + '.pdf')
          setLicenseUrl(url)
        }
        setDiagnosisResult(diagnosisResult)
        setLoading(false)
       
        
      } catch (err) {
        alert("Something went wrong")
        handleReset();
      }
    }
  }
  let imageElement = null;
  if (!pictureFile) {
    imageElement = <div className="emptyblock"><div className="empty"></div></div>
  } else {
    imageElement = <img className="xray" alt="xray" src={preview} height="250px" width="250px" object-fit="contain"></img>
  }


  let alertSeverity = "warning"
  let alertText = "No Results Available"
  if (diagnosisResult) {
    if (diagnosisResult.COVID_POSITIVE) {
      alertSeverity = "error"
      alertText = "COVID POSITIVE"
    } else {

      if (diagnosisResult.output_class === "nonxray") {
        alertSeverity = "warning"
        alertText = "Non X-Ray Image Uploaded"
      } else if (diagnosisResult.output_class === "sizetoosmall"){
        alertSeverity="warning"
        alertText="Uploaded image size is too small \n , required minimum image size 600x600"
      }
      else {
        alertSeverity = "success"
        alertText = "COVID NEGATIVE"
      }

    }

  }
  let alertComponent = null;
  if (diagnosisResult) {
    alertComponent = <Alert className="gap" severity={alertSeverity} variant="filled">{alertText}</Alert>
  }
  if (isLoading) {
    alertComponent = <CircularProgress className="gap" />
  }





  return (
    <div className="main" width={newWidth} height={newHeight}>
      <div className={classes.root + " patient-details"}>
        <TextField
          required
          error={!getIDValid()}
          label="Patient ID"
          placeholder="Enter Patient ID"
          margin="normal"
          value={ID}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => {
            setId(e.target.value);
          }}
        />
        <div className="license">
          <Checkbox checked={agreeLicense} color="primary" name="license" onChange={() => setAgreeLicense(!agreeLicense)} />
          <a href={licenseUrl} rel="noopener noreferrer" target="_blank" > Accept License Agreement</a>
        </div>
      </div>

      <div className="row">
        <div className="left">
        <Tooltip title={ uploadTooltip }
            classes={{ tooltip: classes.customWidth }} arrow>
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ height: "50px", width: "150px" }}
          >
            Upload X-Ray
       <input
              type="file"
              accept=".jpg,.jpeg,.png,.bmp,.dcm"
              onChange={onSelectFile}
              style={{ display: "none" }}
              key={keyForInput}
            />

          </Button>
        </Tooltip>
          {imageElement}
          {pictureFile ? <p>{pictureFile.name}</p> : <p>{" "}</p>}
        </div>
        <div className="middle">
          <Tooltip title={processTooltip} arrow>
            <span>
           <Button style={{ height: "50px", width: "150px" }} onClick={handleSubmit} disabled={!isSubMitEnabled} color="primary" variant="contained">PROCESS</Button>
           </span>
          </Tooltip>
          <Alert severity="info">Uploaded image will be deleted from server after processing the request</Alert>
          {diagnosisResult && <p>{diagnosisResult.timestamp+" IST"}</p>}
          {alertComponent}
        </div>
        <div className="right">
          <div className="row"><Button style={{ height: "50px", width: "150px" }} disabled={!diagnosisResult || diagnosisResult.result_path === "unknown"} onClick={handleSave} color="primary" variant="contained" >SAVE RESULT</Button></div>
          {/* <div className="row"> <Button style={{ height: "50px", width: "150px" }} disabled={!diagnosisResult} onClick={handlePrint} color="primary" variant="contained">PRINT RESULT</Button></div> */}
          
             <Tooltip title={resetTooltip} arrow>
             <div className="row">
               <Button style={{ height: "50px", width: "150px" }} onClick={handleReset} color="primary" variant="contained">RESET </Button>
              </div>
             </Tooltip>
          <div className="row"><a href="mailto:contact@xrayaicovidtest.com">Contact Us</a></div>
        </div>
      </div>
    </div>
  );
}

export default CovidXRayTest;
