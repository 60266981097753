import React, { Component } from 'react';
import './App.css';
import { withAuthenticator , SignOut, SignIn , RequireNewPassword } from 'aws-amplify-react'
import Amplify , {Hub} from 'aws-amplify';
import aws_exports from './aws-exports';
import CovidXRayTest from './components/CovidXRayTest';

Amplify.configure(aws_exports);

class App extends Component {

  constructor(){
    super();
    Hub.listen('auth', (data) => {     
      console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
    })
  }


  render() {
    return (
      <div className="App">
        {/* <AmplifySignOut /> */}
         <div className="header">
             <h2 className="heading"> COVID-19 X-Ray Diagnostic Assistive Tool </h2>
             <div className="signOutDiv">
                <SignOut theme={buttonTheme}/>
            </div>
        </div>
        <CovidXRayTest/>
        <div className="footer">
          <p><strong>Disclaimer:</strong> This COVID-19 X-Ray Diagnostic Assistive Tool is for research and evaluation purposes by qualified medical professionals only and must not be used for medical diagnosis. </p>
        </div>
      </div>
    );
  }
}

const buttonTheme={
  navButton: {backgroundColor :"#303f9f", borderColor : "#303f9f" , borderRadius: "6px"}
}

const MyTheme = { 
  
  label : { backgroundColor: "#303f9f", borderColor: "#303f9f" },
  a :  { color: "#303f9f" },
  button : { backgroundColor : "#303f9f" ,  borderColor: "#303f9f", borderRadius: "6px" },
  nav :{ backgroundColor : "black" , color: "white" , height:"40px"}

};



export default withAuthenticator(App, false , [ 
<SignIn/>,
<RequireNewPassword/>
  ], null , MyTheme );
