/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInferenceHistory = /* GraphQL */ `
  mutation CreateInferenceHistory(
    $input: CreateInferenceHistoryInput!
    $condition: ModelInferenceHistoryConditionInput
  ) {
    createInferenceHistory(input: $input, condition: $condition) {
      id
      user
      imageID
      patientID
      licenseAcceptance
      covidPositive
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateInferenceHistory = /* GraphQL */ `
  mutation UpdateInferenceHistory(
    $input: UpdateInferenceHistoryInput!
    $condition: ModelInferenceHistoryConditionInput
  ) {
    updateInferenceHistory(input: $input, condition: $condition) {
      id
      user
      imageID
      patientID
      licenseAcceptance
      covidPositive
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteInferenceHistory = /* GraphQL */ `
  mutation DeleteInferenceHistory(
    $input: DeleteInferenceHistoryInput!
    $condition: ModelInferenceHistoryConditionInput
  ) {
    deleteInferenceHistory(input: $input, condition: $condition) {
      id
      user
      imageID
      patientID
      licenseAcceptance
      covidPositive
      timestamp
      createdAt
      updatedAt
    }
  }
`;
